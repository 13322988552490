import React, { useState, useEffect, createContext } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, setPersistence, browserLocalPersistence } from 'firebase/auth';

// Create a context to store authentication state
export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [authInitialized, setAuthInitialized] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Initialize Firebase app
  useEffect(() => {
    const firebaseConfig = {
            apiKey: 'AIzaSyD-trxFjnoFTzcS3ih5bzandeKpJXrFzZo',
            authDomain: 'fir-javascript-88ee3.firebaseapp.com',
            databaseURL: 'https://fir-javascript-88ee3-default-rtdb.firebaseio.com',
            projectId: 'fir-javascript-88ee3',
            storageBucket: 'fir-javascript-88ee3.appspot.com',
            messagingSenderId: '761068839123',
            appId: '1:761068839123:web:2c6ea40cfc8592888cb426'
    };
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);

    // Set local persistence
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        // Continue with sign-in
        onAuthStateChanged(auth, (user) => {
          setIsAuthenticated(!!user);
          setAuthInitialized(true);
        });
      })
      .catch((error) => {
        console.error('Error setting persistence:', error);
        setAuthInitialized(true);
      });
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, authInitialized }}>
      {authInitialized && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
