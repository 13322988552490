import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc } from 'firebase/firestore/lite';
import { getAuth, onAuthStateChanged, setPersistence, browserLocalPersistence } from 'firebase/auth'; // Use browserLocalPersistence
import { useNavigate } from 'react-router-dom';
import MAIN_SIGN_OUT from './main_sign_out';


const AccountDashboard = () => {
  const [userData, setUserData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSignedIn, setIsSignedIn] = useState(false);
  const navigate = useNavigate();

  // Replace with your Firebase configuration
  const firebaseConfig = {
    apiKey: 'AIzaSyD-trxFjnoFTzcS3ih5bzandeKpJXrFzZo',
    authDomain: 'fir-javascript-88ee3.firebaseapp.com',
    databaseURL: 'https://fir-javascript-88ee3-default-rtdb.firebaseio.com',
    projectId: 'fir-javascript-88ee3',
    storageBucket: 'fir-javascript-88ee3.appspot.com',
    messagingSenderId: '761068839123',
    appId: '1:761068839123:web:2c6ea40cfc8592888cb426'
  };

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth(app);

  useEffect(() => {
    // Set local persistence
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        // Continue with sign-in
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
          if (user) {
            setIsSignedIn(true);
            try {
              const userDoc = await getDoc(doc(db, 'users', user.uid));
              if (userDoc.exists()) {
                setUserData(userDoc.data());
              } else {
                setErrorMessage('User data not found');
              }
            } catch (error) {
              console.error('Error retrieving user data:', error);
              setErrorMessage('Error retrieving user data');
            }
          } else {
            setIsSignedIn(false);
            setErrorMessage('User not signed in');
          }
        });
        return () => unsubscribe();
      })
      .catch((error) => {
        console.error('Error setting persistence:', error);
      });
  }, [auth, db]);

  const handleSignOut = async () => {
    try {
      // Sign out the user
      await auth.signOut();
      setIsSignedIn(false);
      navigate('/signin');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  if (!isSignedIn) {
    navigate('/signin');
    return null;
  }

  return (
    <div>
      <h1>Account Dashboard</h1>

      {userData ? (
        <div>
          <h2>User Profile</h2>
          <p>Name: {userData.name}</p>
          <p>Email: {userData.email}</p>
          <p>Phone Number: {userData.phoneNumber}</p>
          <MAIN_SIGN_OUT onSignOut={handleSignOut} />
        </div>
      ) : (
        <p>Loading dashboard...</p>
      )}

      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
    </div>
  );
};

export default AccountDashboard;
