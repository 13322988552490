import React, { useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, setDoc, doc } from 'firebase/firestore/lite';
import { getAuth, createUserWithEmailAndPassword, setPersistence, browserLocalPersistence} from 'firebase/auth';

const RegisterForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  // Replace with your Firebase configuration
  const firebaseConfig = {
    apiKey: 'AIzaSyD-trxFjnoFTzcS3ih5bzandeKpJXrFzZo',
    authDomain: 'fir-javascript-88ee3.firebaseapp.com',
    databaseURL: 'https://fir-javascript-88ee3-default-rtdb.firebaseio.com',
    projectId: 'fir-javascript-88ee3',
    storageBucket: 'fir-javascript-88ee3.appspot.com',
    messagingSenderId: '761068839123',
    appId: '1:761068839123:web:2c6ea40cfc8592888cb426'
  };

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth(app);

  // Set persistence to LOCAL
  setPersistence(auth, browserLocalPersistence);

  const registerUser = async () => {
    try {
      // Check if any required field is empty
      if (!name || !email || !phoneNumber || !password) {
        throw new Error('Please fill in all fields');
      }
  
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const userId = userCredential.user.uid;
  
      const userData = {
        name: name,
        email: email,
        phoneNumber: phoneNumber,
        user_id: userId
      };
  
      // Set the user document with user ID as the document ID
      await setDoc(doc(db, 'users', userId), userData);
  
      console.log('User registered successfully!', userCredential.user);
      alert('User registered successfully!');
      // You can redirect the user to another page after successful registration if needed
    } catch (error) {
      console.error('Error registering user: ', error);
      setErrorMessage(error.message);
  
      // Set error states for required fields
      if (!name) {
        setNameError(true);
      }
      if (!email) {
        setEmailError(true);
      }
      if (!phoneNumber) {
        setPhoneError(true);
      }
      if (!password) {
        setPasswordError(true);
      }
    }
  };
  
  return (
    <div>
      <h1>Register</h1>

      <label htmlFor="name">Name:</label>
      <input
        type="text"
        id="name"
        placeholder="Enter Name"
        value={name}
        onChange={(e) => {
          setName(e.target.value);
          setNameError(false);
        }}
        style={{ borderBottom: nameError ? '2px solid red' : 'none' }}
      />

      <br />

      <label htmlFor="email">Email:</label>
      <input
        type="email"
        id="email"
        placeholder="Enter Email"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
          setEmailError(false);
        }}
        style={{ borderBottom: emailError ? '2px solid red' : 'none' }}
      />

      <br />

      <label htmlFor="phoneNumber">Phone Number:</label>
      <input
        type="tel"
        id="phoneNumber"
        placeholder="Enter Phone Number"
        value={phoneNumber}
        onChange={(e) => {
          setPhoneNumber(e.target.value);
          setPhoneError(false);
        }}
        style={{ borderBottom: phoneError ? '2px solid red' : 'none' }}
      />

      <br />

      <label htmlFor="password">Password:</label>
      <input
        type="password"
        id="password"
        placeholder="Enter Password"
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
          setPasswordError(false);
        }}
        style={{ borderBottom: passwordError ? '2px solid red' : 'none' }}
      />

      <br />

      <button onClick={registerUser} id="registerUserBtn">
        Register
      </button>

      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
    </div>
  );
};

export default RegisterForm;
