import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, setPersistence, browserLocalPersistence } from 'firebase/auth'; // Import browserLocalPersistence
import { useNavigate } from 'react-router-dom';

const SignInForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  // Replace with your Firebase configuration
  const firebaseConfig = {
    apiKey: 'AIzaSyD-trxFjnoFTzcS3ih5bzandeKpJXrFzZo',
    authDomain: 'fir-javascript-88ee3.firebaseapp.com',
    databaseURL: 'https://fir-javascript-88ee3-default-rtdb.firebaseio.com',
    projectId: 'fir-javascript-88ee3',
    storageBucket: 'fir-javascript-88ee3.appspot.com',
    messagingSenderId: '761068839123',
    appId: '1:761068839123:web:2c6ea40cfc8592888cb426'
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  useEffect(() => {
    // Enable local persistence
    setPersistence(auth, browserLocalPersistence)
      .catch((error) => {
        console.error('Error setting persistence:', error);
      });
  }, [auth]);

  const signInUser = async () => {
    try {
      // Check if any required field is empty
      if (!email || !password) {
        throw new Error('Please fill in all details');
      }

      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log('User signed in successfully!', userCredential.user);
      // Redirect the user to the dashboard page after successful sign-in
      navigate('/dashboard');
    } catch (error) {
      console.error('Error signing in user: ', error);
      setErrorMessage(error.message);
    }
  };

  return (
    <div>
      <h1>Sign In</h1>

      <label htmlFor="email">Email:</label>
      <input
        type="email"
        id="email"
        placeholder="Enter Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <br />

      <label htmlFor="password">Password:</label>
      <input
        type="password"
        id="password"
        placeholder="Enter Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />

      <br />

      <button onClick={signInUser} id="signInBtn">
        Sign In
      </button>

      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
    </div>
  );
};

export default SignInForm;
