import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MAIN_SIGN_UP from './components/main_sign_up';
import MAIN_SIGN_IN from './components/main_sign_in';
import MAIN_DASHBOARD from './components/main_dashboard';
import MAIN_SIGN_OUT from './components/main_sign_out';
import MAIN_AUTH_PROVIDER from './components/main_auth_provider'; // Import AuthProvider
import HOME from './components/main_home'; 

function App() {
  return (
    <MAIN_AUTH_PROVIDER> {/* Wrap your entire application with AuthProvider */}
      <Router>
        <Routes>
          <Route path="/signup" element={<MAIN_SIGN_UP />} />
          <Route path="/signin" element={<MAIN_SIGN_IN />} />
          <Route path="/dashboard" element={<MAIN_DASHBOARD />} />
          <Route path="/signout" element={<MAIN_SIGN_OUT />} />
          <Route path="/" element={<HOME />} /> {/* Render Home component */}
        </Routes>
      </Router>
    </MAIN_AUTH_PROVIDER>
  );
}

export default App;
